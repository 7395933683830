import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { LayoutConfiguration } from '../interfaces/layout-configuration';
import { NativeBridgeService } from '../../native-bridge/native-bridge.service';

@Injectable({
    providedIn: 'root'
})
export class HybridLayoutConfigurationService {
    constructor(
        private nativeBridgeService: NativeBridgeService
    ) {}

    /**
     * The getConfiguration function sets the show property of components to false
     * when the hybrid mode is being used
     * @param route ActivatedRouteSnapshot
     * @returns config determined layout when in hybrid mode
     */
    getConfiguration(route: ActivatedRouteSnapshot): LayoutConfiguration {
        const config: LayoutConfiguration = route.data.layoutConfiguration;

        if (this.nativeBridgeService.isEmbedded()) {
            config.showFullHeader = false;
            config.showFullFooter = false;
            config.showLogoHeader = false;
            config.showLegalPlusFooter = false;
            config.showLiveChat = false;
        }

        return config;
    }
}
