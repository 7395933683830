import { Component, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';

import { ViewChildProperties } from '../interfaces/view-child-properties.interface';

@Component({
    selector: 'dcl-chip',
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements AfterViewInit {
    @Input() icon: string;
    @Input() text: string;
    @ViewChild('icon', {static: false} as ViewChildProperties) iconRef: ElementRef;

    ngAfterViewInit() {
        if (this.icon) {
            this.iconRef.nativeElement.classList.add(this.icon);
        }
    }
}
