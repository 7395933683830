<div
    class="dcl-expansion-panel"
    [ngClass]="{'reversed-direction': reversedDirection, 'embedded': isEmbedded}">
    <button class="dcl-expansion-panel-header"
        (click)="toggle()"
        [ngClass]="{ open: open }"
        [attr.aria-expanded]="open"
        [attr.aria-label]="(open ? a11yOpenedLabel : a11yClosedLabel)">
        <div class="dcl-expansion-panel-header__main-content">
            <img
                *ngIf="imageSrc"
                aria-hidden="true"
                class="option-image"
                src="{{ imageSrc }}">
            <div class="dcl-expansion-panel-header-body">
                <span class="dcl-expansion-panel-header-body__title" [attr.aria-hidden]="!open">
                    {{ title }}
                </span>
                <p
                    class="dcl-expansion-panel-header-body__subtitle"
                    aria-hidden="true"
                    *ngIf="subtitle"
                    [innerHTML]="subtitle">
                </p>
                <span class="dcl-expansion-panel-header-body__icon" *ngIf="hideIcon">
                    <ng-content select="[custom-icon]"></ng-content>
                </span>
                <span
                    *ngIf="!hideIcon"
                    class="dcl-expansion-panel-header-body__icon">
                    <i class="di di-next-2" aria-hidden="true"></i>
                </span>
            </div>
        </div>
        <ng-content select="[custom-subcontent]"></ng-content>
    </button>
    <span class="sr-only" *ngIf="subtitle">{{ subtitle }}</span>
    <div class="dcl-expansion-panel-content-wrapper"
        [attr.aria-hidden]="!open"
        [class.expanded]="!preventAutoToggling && open">
        <div class="dcl-expansion-panel-content">
            <ng-content></ng-content>
        </div>
    </div>
</div>
