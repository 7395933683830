import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'dcl-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    styleUrls: ['./expansion-panel.component.scss']
    /**
     * Collapsable transition is implemented with css
     * Angular transition is not executing when the module is loaded as a child in the routes.
     */
})
export class ExpansionPanelComponent {
    @Input() a11yClosedLabel = '';
    @Input() a11yOpenedLabel = '';
    @Input() hideIcon: boolean;
    @Input() imageSrc: string;
    @Input() isEmbedded: boolean;
    @Input() open = false;
    @Input() preventAutoToggling = false;
    @Input() reversedDirection: boolean;
    @Input() subtitle: string;
    @Input() title: string;
    @Output() onPanelOpened = new EventEmitter<boolean>();

    /**
     * Toggles the open property and emits its value.
     */
    public toggle() {
        if (!this.preventAutoToggling) {
            this.open = !this.open;
        }
        this.onPanelOpened.emit(this.open);
    }
}
