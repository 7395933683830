import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CruisePricingComponent } from './cruise-pricing.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [CruisePricingComponent],
    exports: [
        CruisePricingComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CruisePricingModule {
}
