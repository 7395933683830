import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ReservationSummaryBarComponent } from './reservation-summary-bar.component';
import { SailingDatePipeModule } from '../sailing-date/sailing-date.module';
import { SailingDatePipe } from '../sailing-date/sailing-date.pipe';

@NgModule({
    imports: [
        CommonModule,
        SailingDatePipeModule,
        TranslateModule
    ],
    providers: [
        SailingDatePipe,
        TranslateService
    ],
    declarations: [
        ReservationSummaryBarComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        ReservationSummaryBarComponent
    ]
})
export class ReservationSummaryBarModule {}
