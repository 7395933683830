import { catchError, finalize, map, share } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';

import { AuthStatusResponse } from '@interfaces/auth-status-response';
import { ConfigService } from '@app/core/config.service';
import { COOKIES, ENDPOINTS } from '@app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private checkStatusCall: boolean;
    baseUrl: string;
    durationIsLoggedInCookie: number;
    observableAuthzStatus: Observable<boolean>;
    isLoggedInStatus: boolean;

    constructor (
        private configService: ConfigService,
        private cookieService: CookieService,
        private httpClient: HttpClient
    ) {
        this.baseUrl = this.configService.getValue('baseUrl');
        this.durationIsLoggedInCookie = this.configService
            .getValue(COOKIES.durationIsLoggedInCookie);
    }

    /**
     * Gets user authentication status and check if it is valid
     * @returns Auth status
     */
    getAndCheckStatus(): Observable<boolean> {
        // Validation to avoid multiple http calls
        if (!this.observableAuthzStatus) {
            this.observableAuthzStatus = this.getStatus()
                .pipe(
                    share(),
                    map((response: AuthStatusResponse) => {
                        if (response.isLoggedIn) {
                            this.setLoggedInCookie(response);
                        } else {
                            this.isLoggedInStatus = false;
                            this.checkStatusCall = true;
                        }

                        return response.isLoggedIn;
                    }),
                    catchError(() => {
                        this.isLoggedInStatus = false;
                        this.checkStatusCall = true;

                        return of(false);
                    }),
                    finalize(() => {
                        delete this.observableAuthzStatus;
                    })
                );
        }

        return this.observableAuthzStatus;
    }

    /**
     * Gets user authentication status
     * @returns Log in status call
     */
    getStatus(): Observable<AuthStatusResponse> {
        return this.httpClient.get<AuthStatusResponse>(
            `${this.baseUrl}${ENDPOINTS.authenticationStatus}`
        );
    }

    /**
     * Validates if user is logged in
     * @returns boolean value that indicates if user is logged in
     */
    isLoggedIn(): Observable<boolean> {
        const isLoggedInCookie = this.cookieService.get(COOKIES.isLoggedIn);
        let loginDetails: AuthStatusResponse;

        try {
            loginDetails = JSON.parse(isLoggedInCookie);
        } catch (error) {
            loginDetails = null;
        }

        return !!loginDetails && loginDetails.isLoggedIn ? of(true) : this.checkStatus();
    }

    /**
     * Once the first status call has ended then
     * Check is page has fully loaded before making the next status call
     * @returns boolean value that indicates is user is logged in
     */
    checkStatus(): Observable<boolean> {
        let isLoggedIn: Observable<boolean>;

        if (this.checkStatusCall) {
            isLoggedIn = of(this.isLoggedInStatus);
        } else {
            isLoggedIn = this.getAndCheckStatus();
        }

        return isLoggedIn;
    }

    /**
     * Set checkStatusCall property
     * @param checkStatusCall
     */
    setCheckStatusCall(checkStatusCall: boolean): void {
       this.checkStatusCall = checkStatusCall;
    }

    /**
     * Save isLoggedIn cookie from status response
     * @param loginDetails status response
     */
    // IMPORTANT: THIS FUNCTION WILL BE USEFUL WHEN LOGIN SERVICE IS FULLY IMPLEMENTED
    setLoggedInCookie(loginDetails: AuthStatusResponse): void {
        const cookieValue = JSON.stringify({
            isLoggedIn: loginDetails.isLoggedIn
        });
        const expires = moment().add(this.durationIsLoggedInCookie, 'minutes').toDate();
        this.cookieService.set(COOKIES.isLoggedIn, cookieValue, expires, '/');
    }
}
