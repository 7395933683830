import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AbsTrimmerModule } from '../abs-trimmer/abs-trimmer.module';
import { AbsTrimmerPipe } from '../abs-trimmer/abs-trimmer.pipe';
import { NativeBridgeService } from './native-bridge.service';
import { WindowRef } from '../window-ref/window-ref.service';

@NgModule({
    imports: [
        AbsTrimmerModule,
        CommonModule
    ],
    providers: [
        AbsTrimmerPipe,
        NativeBridgeService,
        WindowRef
    ]
})
export class NativeBridgeModule { }
