import {
    Component,
    Input
} from '@angular/core';

import { PriceSummarySimple } from '../interfaces/price-summary-simple';

/**
 * Generic and basic Price summary to display items in a row
 *
 * ## How to use it? -------------------------------------------------------
 *
 * -- in your JS component
 * const summaryPrice = {
 *      body: [
 *          {
 *              text: 'some text for item 1',
 *              value: 222.25,
 *              currency: 'USD'
 *          },
 *          {
 *              text: 'some text for item 2',
 *              value: 333.25,
 *              currency: 'USD',
 *              hideCurrency: false, (optional - false by default)
 *              hideDecimals: false, (optional - false by default)
 *              convertedText: '', (optional)
 *              convertedValue: 123, (optional)
 *              convertedCurrency: 'CAD', (optional)
 *          }
 *      ],
 *      footer: {
 *          text: 'some text for footer',
 *          value: 555.50',
 *          currency: 'USD',
 *          hideCurrency: false, (optional - false by default)
 *          hideDecimals: false, (optional - false by default)
 *          convertedText: '', (optional)
 *          convertedValue: 123, (optional)
 *          convertedCurrency: 'CAD', (optional),
 *          hideConvertedCurrency: false, (optional - false by default)
 *          hideConvertedDecimals: false, (optional - false by default)
 *      },
 *      title: 'My Price Summary Title'
 *  } as PriceSummarySimple;
 *
 * -- in your HTML:
 * <dcl-price-summary-simple [data]="summaryPrice"></dcl-price-summary-simple>
 *
 * ## You can change these styles values in your implementation ------------
 *
 * // determine the background color of the container
 * --wdpr-price-summary-simple-bg: var(--primary-background-theme);
 *
 * // determine the padding of the container for Desktop and Tablet
 * --wdpr-price-summary-simple-padding: 32px 30px;
 *
 * // determine the padding of the container for Mobile
 * --wdpr-price-summary-simple-padding-mobile: 24px 16px;
 *
 * // determine the font of the text for Desktop and Mobile
 * --wdpr-price-summary-simple-item__text-font-family: InspireTWDC;
 *
 * # Wiki for wdpr-price component
 * @see https://github.disney.com/WDPR-RA-UI-Components/wdpr-price
 */
@Component({
    selector: 'dcl-price-summary-simple',
    templateUrl: './price-summary-simple.component.html',
    styleUrls: ['./price-summary-simple.component.scss']
})
export class PriceSummarySimpleComponent {
    @Input() data: PriceSummarySimple;
    @Input() isIntl: boolean;
}
