import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { GuestSelectorHeaderComponent } from './guest-selector-header.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    providers: [
        TranslateService
    ],
    declarations: [
        GuestSelectorHeaderComponent
    ],
    exports: [
        GuestSelectorHeaderComponent
    ]
})
export class GuestSelectorHeaderModule {}
