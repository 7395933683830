import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { BoldValuePipe } from './bold-values.pipe';
import { TypeAheadComponent } from './type-ahead.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    declarations: [
        TypeAheadComponent,
        BoldValuePipe
    ],
    exports: [
        TypeAheadComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class TypeAheadModule {
}
