import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScriptLoaderComponent } from './script-loader.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ScriptLoaderComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    exports: [
        ScriptLoaderComponent
    ]
})
export class ScriptLoaderModule {}
