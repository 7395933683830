import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CarouselHeroImageComponent } from './carousel-hero-image.component';
import { HeroImageModule } from '../hero-image/hero-image.module';

@NgModule({
    imports: [
        CommonModule,
        HeroImageModule
    ],
    declarations: [
        CarouselHeroImageComponent
    ],
    exports: [
        CarouselHeroImageComponent
    ]
})
export class DclCarouselHeroImageModule { }
