import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { BaymaxHeaderModule } from '../baymax-header/baymax-header.module';
import { ItinerariesModalComponent } from './itineraries-modal.component';
import { SideModalModule } from '../side-modal/side-modal.module';
import { TableModule } from '../table/table.module';

@NgModule({
    declarations: [
        ItinerariesModalComponent
    ],
    imports: [
        BaymaxHeaderModule,
        CommonModule,
        SideModalModule,
        TableModule,
        TranslateModule,
    ],
    exports: [
        ItinerariesModalComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class ItinerariesModalModule { }
