import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CastMemberHeaderComponent } from './cast-member-header.component';
import { StickyBarModule } from '../sticky-bar/sticky-bar.module';

@NgModule({
    imports: [CommonModule, TranslateModule, StickyBarModule],
    declarations: [CastMemberHeaderComponent],
    exports: [CastMemberHeaderComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class CastMemberHeaderModule {}
