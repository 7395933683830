import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormattedPhoneMask } from '../interfaces/formatted-phone-mask.interface';

/**
 * Directive to mask input into passed phone mask
 * A number mask is composed by:
 * 1. # characters, each of them representing a digit to be entered by the user.
 * 2. Every other character is fixed in the format, such as dashes and parenthesis.
 * n example :  (###)###-###/###   ->    (123)567-890/123
 */
@Directive({
    selector: '[formControlName][dclPhoneInputMask]'
})
export class PhoneInputMaskDirective {
    @Input() dclPhoneInputMask: string;

    constructor(public ngControl: NgControl, private el: ElementRef) { }

   /**
    * Handle ngModelChange event fired by the input
    * @param phoneNumber new value set to the model
    */
    @HostListener('ngModelChange', ['$event'])
    onModelChange(phoneNumber: String): void {
        let formatWithNumberMaskResult: FormattedPhoneMask;
        let formattedString: String;
        let cursorPosition: Number;

        if (this.dclPhoneInputMask) {
            formatWithNumberMaskResult = this.formatWithNumberMask(phoneNumber, this.dclPhoneInputMask);
            formattedString = formatWithNumberMaskResult.formattedString;
            cursorPosition = formatWithNumberMaskResult.cursorPosition;

            // Update the value of the input
            this.ngControl.valueAccessor.writeValue(formattedString);

            if (formattedString) {
                // Update the position of the cursor
                this.el.nativeElement.setSelectionRange(cursorPosition, cursorPosition);
            }
        }
    }

    /**
     * Helper function to format a string by a given number mask
     * Parameters example
     * newInputValue: "2"
     * numberMask: "(###) ###-####"
     * @param newInputValue
     * @param numberMask
     * @returns - with properties formattedString and cursorPosition
     */
    formatWithNumberMask(newInputValue: String, numberMask: String): FormattedPhoneMask {
        // We strip the newInputValue, leaving only digits. For the example, strippedValue will be "2"
        const strippedValue = newInputValue.replace(/[^0-9]/g, '');
        const strippedChars = strippedValue.split(''); // For the example, strippedChars will be ["2"]
        let charsCount = 0; // Used to iterate over strippedChars
        let formattedString = ''; // Used to return the formatted string
        let cursorPosition = 0; // Used to return the position of the cursor regarding the formatted string
        let maskItem;

        if (strippedValue) {
            // We iterate over the numberMask string
            numberMask.split('').forEach(character  => {
                maskItem = character;
                // We iterate over the strippedChars using the variable charsCount
                if (strippedChars[charsCount]) {
                    // If the current maskItem is '#', we replace it with current strippedChars
                    if (/\#/.test(maskItem)) {
                        formattedString += strippedChars[charsCount];
                        charsCount++;
                        // Else, we should add the maskItem, such as '-', '(' and ' '
                    } else {
                        formattedString += maskItem;
                    }
                    cursorPosition++;
                    // If the iterations over strippedChars are over, complete with mask
                } else {
                    formattedString += maskItem;
                }
            });
        }

        return {formattedString: formattedString, cursorPosition: cursorPosition};
    }

}
