import { NgModule } from '@angular/core';

import { DynamicLanguageDirective } from './dynamic-language.directive';

@NgModule({
    declarations: [
        DynamicLanguageDirective
    ],
    exports: [
       DynamicLanguageDirective
    ]
})
export class DynamicLanguageModule {}
