import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SpecialBannerComponent } from './special-banner.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        SpecialBannerComponent
    ],
    exports: [
        SpecialBannerComponent
    ]
})
export class SpecialBannerModule { }
