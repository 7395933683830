import { NgModule } from '@angular/core';

import { InputNumberDirective } from './input-number.directive';
import { WindowRef } from '../window-ref/window-ref.service';

@NgModule({
    declarations: [
        InputNumberDirective
    ],
    providers: [
        WindowRef
    ],
    exports: [
        InputNumberDirective
    ]
})
export class InputNumberModule { }
