import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageModalComponent } from './message-modal.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    providers: [
        TranslateService
    ],
    declarations: [MessageModalComponent],
    exports: [
        MessageModalComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})

export class MessageModalModule { }
