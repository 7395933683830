import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CurrencyDisclaimerComponent } from './currency-disclaimer.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        CurrencyDisclaimerComponent
    ],
    declarations: [
        CurrencyDisclaimerComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class CurrencyDisclaimerModule {}
