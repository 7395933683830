import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { PriceSummaryComponent } from './price-summary.component';
import { PriceSummarySimpleComponent } from './simple/price-summary-simple.component';
import { AgeGroupValuePipe } from './pipes/age-group-value.pipe';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        PriceSummaryComponent,
        PriceSummarySimpleComponent,
        AgeGroupValuePipe
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    exports: [
        PriceSummaryComponent,
        PriceSummarySimpleComponent
    ]
})
export class PriceSummaryModule {}
