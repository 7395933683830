import { Directive, OnInit, OnDestroy} from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[dclDynamicLanguage]',
})
export class DynamicLanguageDirective implements OnInit, OnDestroy {
    private subscription: Subscription;
    selectedLang: string;

    constructor(
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.subscription = this.translate.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
            this.selectedLang = langChangeEvent.lang || this.translate.currentLang || this.translate.defaultLang;
            document.documentElement.lang = this.selectedLang;
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.translate.onLangChange.unsubscribe();
        }
    }
}
