import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { CssLoaderComponent } from './css-loader/css-loader.component';
import { IconLoaderComponent } from './icon-loader/icon-loader.component';
import { ScriptLoaderModule } from '../script-loader/script-loader.module';
import { SyndicatedFooterComponent } from './syndicated-footer/syndicated-footer.component';
import { HeaderSyndicatedComponent } from './header-syndicated/header-syndicated.component';

@NgModule({
    imports: [
        CommonModule,
        ScriptLoaderModule,
        TranslateModule
    ],
    declarations: [
        CssLoaderComponent,
        IconLoaderComponent,
        SyndicatedFooterComponent,
        HeaderSyndicatedComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    exports: [
        IconLoaderComponent,
        SyndicatedFooterComponent,
        HeaderSyndicatedComponent
    ]
})
export class SyndicatedLayoutModule {}
