import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AlertMessagingComponent } from './alert-messaging.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        AlertMessagingComponent
    ],
    exports: [
        AlertMessagingComponent
    ]
})
export class AlertMessagingModule { }
