import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';

import { TranslateModule } from '@ngx-translate/core';

import { AvatarHeaderModule } from '../avatar-header/avatar-header.module';
import { ExtendedSelectComponent } from './extended-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LinkButtonModule } from '../link-button/link-button.module';

@NgModule({
    imports: [
        AvatarHeaderModule,
        CommonModule,
        LinkButtonModule,
        MatOptionModule,
        MatSelectModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        ExtendedSelectComponent
    ],
    exports: [
        ExtendedSelectComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class ExtendedSelectModule { }
