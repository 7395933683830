import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ErrorStatesComponent } from './error-states.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        ErrorStatesComponent
    ],
    exports: [
        ErrorStatesComponent
    ]
})
export class ErrorStatesModule { }
