import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { StateroomAttributesComponent } from './stateroom-attributes.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        StateroomAttributesComponent
    ],
    exports: [
        StateroomAttributesComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class StateroomAttributesModule { }
