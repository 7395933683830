<section class="pss">
    <!-- header -->
    <div *ngIf="data.title"
        class="pss__header">
        <h3 *ngIf="data.title"
            class="pss__title"
            translate>
            {{ data.title }}
        </h3>
    </div>
    <!-- body -->
    <div *ngIf="data.body"
        class="pss__body">
        <ng-template ngFor let-item [ngForOf]="data.body">
            <div class="pss-price-item">
                <div class="pss-price-item__text"
                    translate>
                    {{ item.text }}
                </div>
                <div>
                    <span *ngIf="item.isNegative">-</span>
                    <wdpr-price
                        class="pss-price-item__price"
                        presentation="inline"
                        [value]="item.value"
                        [currency]="item.currency"
                        [hideCurrency]="item.hideCurrency || false"
                        [hideDecimals]="item.hideDecimals || false">
                    </wdpr-price>
                </div>
            </div>
            <div *ngIf="isIntl"
                class="pss-price-item-intl">
                <div *ngIf="item.convertedText"
                    class="pss-price-item__text"
                    translate>
                    {{ item.convertedText }}
                </div>
                <wdpr-price
                    class="pss-price-item-intl__price"
                    presentation="inline"
                    [value]="item.convertedValue"
                    [currency]="item.convertedCurrency"
                    [hideCurrency]="item.hideConvertedCurrency || false"
                    [hideDecimals]="item.hideConvertedDecimals || false">
                </wdpr-price>
            </div>
        </ng-template>
    </div>
    <!-- footer -->
    <div *ngIf="data.footer"
         class="pss__footer">
        <div class="pss-price-item">
            <div class="pss-price-item__text"
                translate>
                {{ data.footer.text }}
            </div>
            <wdpr-price
                class="pss-price-item__price"
                presentation="inline"
                [value]="data.footer.value"
                [currency]="data.footer.currency"
                [hideCurrency]="data.footer.hideCurrency || false"
                [hideDecimals]="data.footer.hideDecimals || false">
            </wdpr-price>
            <div *ngIf="isIntl"
                class="pss-price-item-intl">
                <div *ngIf="data.footer.convertedText"
                    class="pss-price-item__text"
                    translate>
                    {{ data.footer.convertedText }}
                </div>
                <wdpr-price
                    class="pss-price-item-intl__price"
                    presentation="inline"
                    [value]="data.footer.convertedValue"
                    [currency]="data.footer.convertedCurrency"
                    [hideCurrency]="data.footer.hideConvertedCurrency || false"
                    [hideDecimals]="data.footer.hideConvertedDecimals || false">
                </wdpr-price>
            </div>
        </div>
    </div>
    <!-- legend -->
    <div *ngIf="data.legend"
        class="pss__legend"
        class="text"
        [innerHtml]="'data.legend' | translate">
    </div>
</section>
