import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ViewChildProperties } from '../interfaces/view-child-properties.interface';

import { CurrencyDisclaimerType } from './currency-disclaimer-type.enum';

@Component({
    selector: 'dcl-currency-disclaimer',
    templateUrl: './currency-disclaimer.component.html',
    styleUrls: ['./currency-disclaimer.component.scss'],
})
export class CurrencyDisclaimerComponent {
    @Input() type: CurrencyDisclaimerType = CurrencyDisclaimerType.COMPACT;
    @Input() currencyExchangeExplanation: string;
    @Input() shortDescription: string;
    @Input() fullDescription: string;
    @Input() readMoreText: string;
    @Input() readLessText: string;
    @ViewChild('currencyDisclaimer', { static: false } as ViewChildProperties) currencyDisclaimer: ElementRef;

    showFullDescription: boolean = false;
    componentTypes = CurrencyDisclaimerType;

    get currencyDisclaimerMessage(): string {
        if (this.type === CurrencyDisclaimerType.SIMPLE) {
            return this.fullDescription;
        }

        return this.showFullDescription ? this.fullDescription : this.shortDescription;
    }

    /**
     * Toggles explanation text
     */
    toggleDisclaimerDescription(): void {
        this.showFullDescription = !this.showFullDescription;
        this.currencyDisclaimer.nativeElement.focus();
    }
}
