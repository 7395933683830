import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RequiredLabelPipe } from './required-label.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        RequiredLabelPipe
    ],
    declarations: [
        RequiredLabelPipe
    ],
    exports: [
        RequiredLabelPipe
    ]
})
export class RequiredLabelModule { }
