import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { fromEvent, Subscription } from 'rxjs';

import { NavigatorRef } from '../navigator-ref/navigator-ref.service';
import { WindowRef } from '../window-ref/window-ref.service';

@Directive({
    selector: '[dclInfiniteScroll]'
})
export class DclInfiniteScrollDirective implements OnChanges, OnInit, OnDestroy {
    @Input() scrollDistance: number = 2;
    @Input() scrollThrottle: number = 150;
    @Input() specificPage: number;
    @Input() isLastPage: boolean;
    @Input() parentListId: string;
    @Input() totalPages: number;
    @Input() elements: any[];
    @Input() elementsPerPage: number = 5;
    @Input() removeElements = false;
    @Output() listChangedEvent: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output() showTopSpinner: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() showBottomSpinner: EventEmitter<boolean> = new EventEmitter<boolean>();

    currentPage: number;
    items: any[];
    lastPage: number;
    percentageBase = 10;
    scrollTriggerDistance: number;
    stopScroll = false;
    subscription: Subscription;
    totalPagesCalculated: number;

    constructor(
        private elementRef: ElementRef,
        private navigatorRef: NavigatorRef,
        private windowService: WindowRef,
        private zone: NgZone
    ) { }

    ngOnChanges(): void {
        this.totalPagesCalculated = Math.ceil(this.elements.length / this.elementsPerPage);
        this.removeElements ? this.onScrollRemovingElements() : this.onScroll();

        this.removeSubscription();
        this.addScrollEventListener();
    }

    ngOnInit(): void {
        const incrementPercentage = this.scrollDistance / this.percentageBase;

        this.scrollTriggerDistance = this.windowService.nativeWindow.innerHeight * incrementPercentage;

        if (this.removeElements) {
            this.checkManualScroll();
        } else if (this.totalPagesCalculated > 1) {
            this.showBottomSpinner.emit(true);
        }

        this.setInitData(this.specificPage);
        this.showBottomSpinner.emit(false);
    }

    ngOnDestroy(): void {
        this.removeSubscription();
    }

    /**
     * Setup scroll event listener to call onScroll handler on each event fired.
     */
    addScrollEventListener(): void {
        const scrollEvent = fromEvent(this.windowService.nativeWindow, 'scroll');

        scrollEvent.pipe(debounceTime(this.scrollThrottle));

        this.subscription = scrollEvent.subscribe(() => {
            if (!this.stopScroll) {
                this.removeElements ? this.onScrollRemovingElements() : this.onScroll();
            } else {
                this.stopScroll = false;
            }
        });
    }

    /**
     * Scroll handler to emit the new list of elements to render based on the scroll position
     */
    onScroll(): void {
        const containerPosition = this.elementRef.nativeElement.getBoundingClientRect();
        const windowHeight = this.windowService.nativeWindow.innerHeight;

        if (containerPosition.bottom - this.scrollTriggerDistance <= windowHeight) {

            if (this.parentListId && this.totalPagesCalculated >= this.totalPages && this.isLastPage) {
                this.showBottomSpinner.emit(false);
                this.unsubscribeChildElementIsRendered();
            }

            if (this.hasNextElements()) {
                this.currentPage += 1;

                // Checks the next page to hide the spinner and remove the subscription
                if (!this.hasNextElements()) {
                    this.showBottomSpinner.emit(false);
                    this.subscription.unsubscribe();
                }

                const newItems = this.elements.slice(
                    this.currentPage * this.elementsPerPage,
                    this.currentPage * this.elementsPerPage + this.elementsPerPage
                );

                this.items = this.items.concat(newItems);
                this.listChangedEvent.emit(this.items);
            }
        }
    }

    /**
     * Scroll handler to emit the new list of elements to renDer based on the scroll position
     * removing the previous elements loaded
     */
    onScrollRemovingElements(): void {
        const containerPosition = this.elementRef.nativeElement.getBoundingClientRect();

        if (containerPosition.top > 0 && this.currentPage !== 0) {
            const items = this.getPage();

            this.currentPage = 0;
            this.showTopSpinner.emit(false);
            this.listChangedEvent.emit(items);
        } else {
            const windowHeight = this.windowService.nativeWindow.innerHeight;

            if (containerPosition.top + this.scrollTriggerDistance >= 0) {
                if (this.hasPrevElements()) {
                    const items = this.getPrevPage();

                    this.showTopSpinner.emit(true);
                    this.listChangedEvent.emit(items);
                } else {
                    this.showTopSpinner.emit(false);
                }
            } else if (containerPosition.bottom - this.scrollTriggerDistance <= windowHeight) {
                if (this.hasNextElements()) {
                    const items = this.getNextPage();

                    this.showBottomSpinner.emit(true);
                    this.listChangedEvent.emit(items);
                } else {
                    this.showBottomSpinner.emit(false);
                }
            }
        }
    }

    /**
     * Checks if is an IOS device or the browser is Firefox to perform a manual scroll
     * after update the list of elements to render. This is needed due to the scroll behavior
     */
    private checkManualScroll(): void {
        const userAgent = this.navigatorRef.nativeNavigator.userAgent;
        const isIOS = userAgent.match(/iPhone|iPad|iPod/i);
        const isFirefox = userAgent.match(/Firefox/i);

        if (isIOS || isFirefox) {
            this.listChangedEvent.subscribe(() => {
                const currentScroll = this.windowService.nativeWindow.pageYOffset;
                // As the container can have the elements of two pages after load the first one,
                // we need to perform the scroll to the middle position of the first page what
                // is equal to divide the container height in 4
                const scrollOffset = this.elementRef.nativeElement.offsetHeight / 4;

                if (this.currentPage < this.lastPage) {
                    // prev page
                    this.stopScroll = true;

                    // We use the NgZone to perform the scroll without trigger any view change
                    // or scroll listener in order to get better performance
                    this.zone.runOutsideAngular(() => {
                        this.windowService.nativeWindow.scroll(0, currentScroll + scrollOffset);
                    });

                    if (!this.hasPrevElements()) {
                        this.showTopSpinner.emit(false);
                    }
                } else if (this.currentPage > 1) {
                    // next page
                    this.stopScroll = true;

                    // We use the NgZone to perform the scroll without trigger any view change
                    // or scroll listener in order to get better performance
                    this.zone.runOutsideAngular(() => {
                        this.windowService.nativeWindow.scroll(0, currentScroll - scrollOffset);
                    });

                    if (!this.hasNextElements()) {
                        this.showBottomSpinner.emit(false);
                    }
                }

                this.lastPage = this.currentPage;
            });
        }
    }

    /**
     * Sets the initial data
     * @param specificPage page to be loaded
     */
    private setInitData(specificPage?: number): void {
        this.items = this.getPage(specificPage);
        this.currentPage = 0;

        if (specificPage > 0) {
            this.currentPage = specificPage - 1;
        }

        this.lastPage = this.currentPage;
        this.listChangedEvent.emit(this.items);
    }

    /**
     * Returns the first or a specific page based on the elements per page
     * @param specificPage page to be loaded
     * @returns first page of elements
     */
    private getPage(specificPage?: number): any[] {
        if (specificPage > 1) {
            this.currentPage = specificPage;

            if (this.removeElements) {
                // this implementation is because we want to retrieve the pages before and after selected page example
                // selected page = 2, then the function retrieve pages [1, 2, 3]
                const previousIndexPage = 2;

                return this.elements.slice(
                    (this.currentPage - previousIndexPage) * this.elementsPerPage,
                    this.currentPage * this.elementsPerPage + this.elementsPerPage
                );
            } else {
                return this.elements.slice(0, this.currentPage * this.elementsPerPage);
            }
        }

        return this.elements.slice(0, this.elementsPerPage);
    }

    /**
     * Returns the next page of elements
     * @returns next page of elements
     */
    private getNextPage(): any[] {
        const items =  this.elements.slice(
            this.currentPage * this.elementsPerPage,
            (this.currentPage + 1) * this.elementsPerPage + this.elementsPerPage
        );

        this.currentPage += 1;

        return items;
    }

    /**
     * Returns the previous page of elements
     * @returns previous page of elements
     */
    private getPrevPage(): any[] {
        const items =  this.elements.slice(
            (this.currentPage - 1) * this.elementsPerPage,
            this.currentPage * this.elementsPerPage + this.elementsPerPage
        );

        this.currentPage -= 1;

        return items;
    }

    /**
     * Returns if there are next elements to paginate
     * @returns true if there are next elements, false in otherwise
     */
    private hasNextElements(): boolean {
        return this.currentPage + 1 < this.totalPagesCalculated;
    }

    /**
     * Returns if there are previous elements to paginate
     * @returns true if there are previous elements, false in otherwise
     */
    private hasPrevElements(): boolean {
        return this.currentPage - 1 >= 0;
    }

    /**
     * Unsubscribe from the scroll event
     */
    private removeSubscription(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    /**
     * This helps to unsubscribe only when all the elements are shown in the DOM,
     * @requires dcl-infinite-scroll-child: children elements should have this '.dcl-infinite-scroll-child' class.
     * @see https://myjira.disney.com/browse/DCLCOMSUST-4694
     */
    /* istanbul ignore next */
    private unsubscribeChildElementIsRendered(): void {
        const children = this.elementRef.nativeElement.querySelectorAll('.dcl-infinite-scroll-child');

        if (this.elements.length - children.length <= 0) {
            this.subscription.unsubscribe();
        }
    }
}
