import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AbsTrimmerPipe } from './abs-trimmer.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [AbsTrimmerPipe],
    exports: [AbsTrimmerPipe]
})
export class AbsTrimmerModule { }
