import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { DclSideModalComponent } from './side-modal.component';
import { DeviceService } from '../device/device.service';
import { WindowRef } from '../window-ref/window-ref.service';

@NgModule({
    declarations: [
        DclSideModalComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
    ],
    providers: [
        WindowRef,
        DeviceService
    ],
    exports: [
        DclSideModalComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class SideModalModule { }
