import { NgModule } from '@angular/core';
import { PhoneInputMaskDirective } from './phone-input-mask.directive';

@NgModule({
    declarations: [
        PhoneInputMaskDirective
    ],
    exports: [
        PhoneInputMaskDirective
    ]
})
export class PhoneInputMaskModule { }
