import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ContactInfoComponent } from './contact-info.component';
import { ErrorMessageModule } from '../error-message/error-message.module';
import { FormDataService } from '../form-data/form-data.service';
import { ReplaceStringModule } from '../replace-string/replace-string.module';
import { ReplaceStringPipe } from '../replace-string/replace-string.pipe';
import { RequiredLabelModule } from '../required-label/required-label.module';

@NgModule({
    imports: [
        CommonModule,
        ErrorMessageModule,
        ReactiveFormsModule,
        ReplaceStringModule,
        RequiredLabelModule,
        TranslateModule
    ],
    declarations: [
        ContactInfoComponent
    ],
    exports: [
        ContactInfoComponent,
        ReactiveFormsModule
    ],
    providers: [
        FormDataService,
        ReplaceStringPipe
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class ContactInfoModule { }
