import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReplaceStringPipe } from './replace-string.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [ReplaceStringPipe],
    exports: [ReplaceStringPipe]
})
export class ReplaceStringModule { }
