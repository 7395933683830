import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[dclStopPropagation]'
})
export class StopPropagationDirective {
    @HostListener('click', ['$event']) onClick(event: MouseEvent) {
        this.eventHandling(event);
    }

    /**
     * Event Handling
     * @param event MouseEvent
     */
    private eventHandling(event: MouseEvent): void {
        event.stopPropagation();
    }
}
