import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { PopoverComponent } from './popover.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        PopoverComponent
    ],
    exports: [
        PopoverComponent
    ]
})
export class PopoverComponentModule { }
