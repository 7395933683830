<!-- Compact -->
<div *ngIf="type === componentTypes.COMPACT" class="currency-disclaimer">
    <div 
        #currencyDisclaimer
        class="currency-disclaimer__content"
        tabindex="0"
        [attr.aria-label]="currencyExchangeExplanation + currencyDisclaimerMessage">
        <span class="currency-disclaimer__explanation">
            {{ currencyExchangeExplanation }}
        </span>
        <span
            class="currency-disclaimer__full-explanation"
            [innerHTML]="currencyDisclaimerMessage"></span>
    </div>
    <button
        (click)="toggleDisclaimerDescription()"
        class="currency-disclaimer__read-action">
        {{ showFullDescription ? readLessText : readMoreText }}
    </button>
</div>

<!-- Simple -->
<div *ngIf="type === componentTypes.SIMPLE" class="currency-disclaimer--simple">
    <span
        tabindex="0"
        class="currency-disclaimer-text"
        [attr.aria-label]="currencyExchangeExplanation + currencyDisclaimerMessage"
        [innerHTML]="currencyExchangeExplanation + ' ' + currencyDisclaimerMessage">
    </span>
</div>

