import { NgModule } from '@angular/core';

import { DclInfiniteScrollDirective } from './infinite-scroll.directive';

@NgModule({
    declarations: [
        DclInfiniteScrollDirective
    ],
    exports: [
        DclInfiniteScrollDirective
    ]
})
export class DclInfiniteScrollModule {}
