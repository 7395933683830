import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { SailingDatePipe } from './sailing-date.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        DatePipe
    ],
    declarations: [
        SailingDatePipe
    ],
    exports: [
        SailingDatePipe
    ]
})
export class SailingDatePipeModule { }
