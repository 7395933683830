import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ResourceBundleService } from '@app/services/resource-bundle/resource-bundle.service';
import { ConfigService } from '@core/config.service';
import { get } from 'lodash';
import { HeroContent, getHeroContentMode, getMediaEngineId } from '@app/shared/utils/media-engine';
import { RedirectionService } from "@app/services/redirection/redirection.service";
import { Brands } from "@app/shared/utils/utils.enum";

@Component({
    selector: 'pixie-hero',
    templateUrl: './hero.component.html',
    styleUrls: ['./hero.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeroComponent implements OnInit {
    @Input() id: string;
    @Input() titleMobile: any;
    @Input() titleDesktop: any;
    @Input() headerMobile: any;
    @Input() headerDesktop: any;
    @Input() ctasMobile: object[];
    @Input() ctasDesktop: object[];
    @Input() src: any;
    @Input() dataType: string;

    siteId: string;
    isMobile: boolean = false;
    isFast: boolean = false;
    hasFirstCTA: boolean = false;
    hasDesktopBackgroundVideo: boolean = false;
    hasMobileBackgroundVideo: boolean = false;
    mediaKeyBase: string = 'heroes[0].media.';
    mediaKeyBaseLegacy: string = 'heroes[0].descriptions.';
    isOnFullscreen: boolean = false;

    constructor(
        private resourceBundle: ResourceBundleService,
        private configService: ConfigService,
        private redirectionService: RedirectionService
    ) {
        this.siteId = this.configService.getValue('siteId');
        this.isMobile = 'ontouchstart' in window && window.screen.width < 768;
    }

    ngOnInit() {
        this.ctasMobile = [
            {
                alt: '',
                analyticsLinkId: '',
                title: '',
            },
            {
                alt: '',
                analyticsLinkId: '',
                analyticsLinkIdFullscreen: '',
                title: '',
                ctaURL: '',
            },
        ];
        this.ctasDesktop = [
            {
                alt: '',
                analyticsLinkId: '',
                title: '',
            },
            {
                alt: '',
                analyticsLinkId: '',
                analyticsLinkIdFullscreen: '',
                title: '',
                ctaURL: '',
            },
        ];

        window['heroMedia'] = [];
        this.resourceBundle.get('heroes').then(data => {
            // Add data for CTAs
            if (/\bFast\b/i.test(data.heroes[0].name)) {
                this.isFast = true;

                this.headerMobile = data.heroes[0].descriptions.mobileVideoTitle.sections.header;
                this.headerDesktop = data.heroes[0].descriptions.videoTitle.sections.header;
                this.titleMobile = data.heroes[0].descriptions.mobileVideoTitle.sections.title;
                this.titleDesktop = data.heroes[0].descriptions.videoTitle.sections.title;
                
                const analyticsSiteId = this.siteId.toUpperCase();
                this.mediaKeyBase = this.mediaKeyBaseLegacy;
                let ctaDesktop1 = data.heroes[0].descriptions.videoFirstCTA.sections;
                let ctaMobile1 = data.heroes[0].descriptions.mobileVideoFirstCTA.sections;
                let ctaDesktop2 = data.heroes[0].descriptions.videoDetailsCTA.sections;
                let ctaMobile2 = data.heroes[0].descriptions.mobileVideoDetailsCTA.sections;

                if (get(data, 'heroes[0].descriptions.videoFirstCTA', false) && get(data, 'heroes[0].descriptions.mobileVideoFirstCTA', false)) {
                    this.hasFirstCTA = true;
                    this.ctasMobile[0] = {
                        alt: ctaMobile1.alt,
                        analyticsLinkId: ctaMobile1.analyticsLinkId,
                        title: data.heroes[0].descriptions.mobileVideoFirstCTA.sections.title,
                        target: get(data, 'heroes[0].descriptions.mobileVideoFirstCTA.sections.link', '_self'),
                        icon: ctaMobile1.icon,
                        ctaURL: ctaMobile1.ctaURL
                    };
                    this.ctasDesktop[0] = {
                        alt: ctaDesktop1.alt,
                        analyticsLinkId: ctaDesktop1.analyticsLinkId,
                        title: ctaDesktop1.title,
                        target: get(data, 'heroes[0].descriptions.videoFirstCTA.sections.link', '_self'),
                        icon: ctaDesktop1.icon,
                        ctaURL: ctaDesktop1.ctaURL
                    };
                }

                this.ctasMobile[1] = {
                    alt: ctaMobile2.alt,
                    analyticsLinkId: `${analyticsSiteId}_HPMP_Billboard_${ctaMobile2.analyticsLinkId}_Link`,
                    analyticsLinkIdFullscreen: `${analyticsSiteId}_HPMP_InVideo_${ctaMobile2.analyticsLinkId}_Link`,
                    title: ctaMobile2.title,
                    target: get(data, 'heroes[0].descriptions.mobileVideoDetailsCTA.link', '_self'),
                    icon: data.heroes[0].descriptions.mobileVideoDetailsCTA.sections.icon,
                    ctaURL: ctaDesktop2.ctaURL
                };
                this.ctasDesktop[1] = {
                    alt: ctaDesktop2.alt,
                    analyticsLinkId: `${analyticsSiteId}_HPMP_Billboard_${ctaDesktop2.analyticsLinkId}_Link`,
                    analyticsLinkIdFullscreen: `${analyticsSiteId}_HPMP_InVideo_${ctaDesktop2.analyticsLinkId}_Link`,
                    title: ctaDesktop2.title,
                    target: get(data, 'heroes[0].descriptions.videoDetailsCTA.link', '_self'),
                    icon: data.heroes[0].descriptions.videoDetailsCTA.sections.icon,
                    ctaURL: ctaDesktop2.ctaURL
                };

            } else {
                this.titleMobile = data.heroes[0].descriptions.mobileVideoTitle.sections.title;
                const isWdwOrDlr = this.siteId === Brands.WDW || this.siteId === Brands.DLR;
                const heroContent = getHeroContentMode(data);
                const showMobileVideoHkdl = get(data, 'heroes[0].media.mobileVideoDetail', false) || '';
                const showVideoWdwDlr = heroContent === HeroContent.ImageWithVideo || heroContent === HeroContent.VideoAutoplay;
    
                if (showMobileVideoHkdl || showVideoWdwDlr)
                {
                    this.hasMobileBackgroundVideo = true;
                    this.ctasMobile[0] = {
                        alt: data.heroes[0].descriptions.mobileVideoPlayCTA.sections.alt,
                        analyticsLinkId: data.heroes[0].descriptions.mobileVideoPlayCTA.sections.analyticsLinkId,
                        title: data.heroes[0].descriptions.mobileVideoPlayCTA.sections.title,
                        target: get(data, 'heroes[0].descriptions.mobileVideoPlayCTA.sections.target', '_self'),
                    };
                }

                this.titleDesktop = data.heroes[0].descriptions.videoTitle.sections.title;
    
                if (get(data, 'heroes[0].descriptions.videoPlayCTA.sections', false) && (isWdwOrDlr ? showVideoWdwDlr : true))  {
                    this.hasDesktopBackgroundVideo = true;
                    this.ctasDesktop[0] = {
                        alt: data.heroes[0].descriptions.videoPlayCTA.sections.alt,
                        analyticsLinkId: data.heroes[0].descriptions.videoPlayCTA.sections.analyticsLinkId,
                        title: data.heroes[0].descriptions.videoPlayCTA.sections.title,
                        target: get(data, 'heroes[0].descriptions.videoPlayCTA.sections.target', '_self'),
                    };
                }
    
                let ctaDesktop = data.heroes[0].descriptions.videoDetailsCTA;
                ctaDesktop.alt = ctaDesktop.sections.alt;
                let ctaMobile = data.heroes[0].descriptions.mobileVideoDetailsCTA;
                ctaMobile.alt = ctaMobile.sections.alt;
                const analyticsSiteId = this.siteId.toUpperCase();
    
                // val.sections.source adds support for legacy wdw/dlr media
                if (isWdwOrDlr) {
                    ctaDesktop = data.heroes[0].descriptions.videoDetailsCTA.sections;
                    ctaDesktop.link = ctaDesktop.ctaURL;
                    this.mediaKeyBase = this.mediaKeyBaseLegacy;
                    ctaMobile = data.heroes[0].descriptions.mobileVideoDetailsCTA.sections;
                    ctaMobile.link = ctaMobile.ctaURL;
                }
    
                this.ctasMobile[1] = {
                    alt: ctaMobile.alt,
                    analyticsLinkId: `${analyticsSiteId}_HPMP_Billboard_${ctaMobile.analyticsLinkId}_Link`,
                    analyticsLinkIdFullscreen: `${analyticsSiteId}_HPMP_InVideo_${ctaMobile.analyticsLinkId}_Link`,
                    title: ctaMobile.title,
                    target: get(data, 'heroes[0].descriptions.mobileVideoDetailsCTA.target', '_self'),
                    ctaURL: ctaDesktop.link
                };
    
                this.ctasDesktop[1] = {
                    alt: ctaDesktop.alt,
                    analyticsLinkId: `${analyticsSiteId}_HPMP_Billboard_${ctaDesktop.analyticsLinkId}_Link`,
                    analyticsLinkIdFullscreen: `${analyticsSiteId}_HPMP_InVideo_${ctaDesktop.analyticsLinkId}_Link`,
                    title: ctaDesktop.title,
                    target: get(data, 'heroes[0].descriptions.videoDetailsCTA.target', '_self'),
                    ctaURL: ctaDesktop.link
                };
            }
        });
    }

    enterFullscreen(event: Event) {
        event.preventDefault();
        const appComponentMediaEngineId = getMediaEngineId('appComponent', 1);
        const appComponentMediaEngineInstance = window[appComponentMediaEngineId];
        if (!appComponentMediaEngineInstance) {
            window['initConfigOverrides']();
        }

        if (appComponentMediaEngineInstance.config.callbacks.customFullscreenEnter) {
            appComponentMediaEngineInstance.config.callbacks.customFullscreenEnter();
            this.isOnFullscreen = true;
        }
    }

    redirectToUrl(event) {
        this.isOnFullscreen = this.redirectionService.redirectToUrl(event, this.isOnFullscreen, () => this.getCtaUrl());
    }

    getCtaUrl(): string {
        return this.redirectionService.getCtaUrl(this.ctasMobile, this.ctasDesktop);
    }
}


declare global {
  interface Window {
    MEConfig: any;
    ME:any;
  }
}
